<style lang="sass" scoped>

</style>
<template lang="pug">
div
  .bg-white.shadow-sm.mb-2
    .p-3
      h5 변경내역 조회

      hr
      p 목록에 있는 레코드중에서 추가/변경/삭제된 내역(누가, 무엇을)을 레코드단위로 나열한다.
      p 레코드를 누르면 상세 변경내역이 뜬다.
  //- (style='margin-top: -61px; position: relative; z-index: 1')
  //- div.container-search.bg-white.shadow-sm.pl-2
    router-link(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px')
        b-icon-chevron-left
        | 뒤로
    strong.text-secondary.ml-4.mr-2 {{document.name}}

  //- form(@submit.prevent='submit')
    table.table(v-if='row.id')
      tbody
        tr
          th.bg-light.w-25.text-right ID
          td
            input.form-control(type='text' v-model='row.external_id' readonly autofocus)
        tr(v-for='(col, $index) in document.config.cols')
          th.bg-light.w-25.text-right {{col.label}}
          td
            input.form-control(type='text' v-model='row.json[col.key]')
        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove') 삭제


</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'on_updated'],
  components: {

  },
  computed: {

  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      saving: false,
      saving_text: '저장',
      document: {},
      row: {},
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
  },
  methods: {
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${decodeURIComponent(this.record_id)}`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객 데이터 가져오기 실패')
        this.row = r.data.row

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      this.saving = true
      this.saving_text = '저장 중'
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${decodeURIComponent(this.record_id)}`, {
          row: this.row.json,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

        if (this.row.external_id != r.data.external_id) {
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}/record/${r.data.external_id}`,
          })
        } else {
          this.load()
        }

        this.saving = false
        this.saving_text = '저장됨'

        this.$emit('updated')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove() {
      try {
        if(!confirm('삭제하시겠습니까?')) return false

        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.record_id}`)
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
  },
}
</script>
